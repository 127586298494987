import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import Logo from './images/ZaytaWebLogoBeta1.png'
import './CSS/NavBar.css';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigation = [
    { name: 'Home', href: '/'},
    { name: 'Blog', href: '/'},
    { name: 'Services', href: '/Services' },
    { name: 'About', href: '/' },
    { name: 'Contact', href: '/' }
  ];

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-content">
          {/* Logo section */}
          <div className="navbar-logo">
            <Link to="/" className="logo-link">
              <img src={Logo} alt="Zayta" className="logo-image" />
            </Link>
          </div>

          {/* Desktop navigation */}
          <div className="navbar-links-desktop">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="nav-link"
              >
                {item.name}
              </Link>
            ))}
          </div>

          {/* Mobile menu button */}
          <div className="navbar-mobile-button">
            <button
              type="button"
              className="mobile-menu-button"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="navbar-mobile-menu">
          <div className="mobile-menu-links">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="mobile-nav-link"
                onClick={() => setIsOpen(false)}
              >
                {item.name}
              </Link>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;