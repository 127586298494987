import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Link } from 'lucide-react';
import './CSS/Services.css';
/*import './images/Images Services';*/ 
import Modal from './ServiceModal';
import FamPlan from './images/ImagesServices/image1.jpg'
import BuizPlan from './images/ImagesServices/image2.jpg'
import CommunityPlan from './images/ImagesServices/image3.jpg'

const handleEmailClick = (e, service) => {
    e.preventDefault(); // Prevent default navigation
    
    const email = "ZaytaLLC@gmail.com";
    const subject = `Booking Request: ${service.title}`;
    const body = `Hi, I'm interested in booking the ${service.title} package. Please contact me with more information about availability.`;
    
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    
    // Open email client without leaving the page
    window.open(mailtoLink, '_blank');
  };

const Services = () => {
  
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const handleServiceCardClick = (service) => {
        setSelectedService(service);
        setIsModalOpen(true);
      };
    
    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedService(null);
      };
    
    const [indices, setIndices] = useState({
        family: 0,
        b2b: 0,
        community: 0
    });

    const services = {
      family: [
        { 
          title: "Family Reunions",
          description: "Creating lasting memories for generations.",
          image: FamPlan,
          modalContent: {
            details: "Our Family Reunion service will personally...",
            features: [
                "Custom Event timeline planning",
                "Activities that'll entertain across the generations"
            ],
            pricing: "Starting at $X per person",
            additionalInfo: "We can accomdae groups of 20 - 100 people"
          }
        },
        { 
          title: "Birthday Celebrations",
          description: "Personalized parties for every age.",
          image: CommunityPlan
        },
        { 
          title: "Baby Showers and Christenings", 
          description: "Celebrating new beginnings.",
          // No image for this one - that's ok!
        },
        { 
          title: "Weddings and Engagements", 
          description: "Your dream, our expertise, and a perfect day.",
          image: "/path/to/financial.jpg"
        }
      ],
      b2b: [
        { 
          title: "Corporate Events", 
          description: "Elevate your brand with unforgettable events.",
          image: "/path/to/consulting.jpg"
        },
        { 
          title: "Product Launches", 
          description: "Create buzz and drive sales.",
        },
        { 
          title: "Conferences and Seminars", 
          description: "Seamlessly executed, impactful events.",
          image: "/path/to/market.jpg"
        },
        { 
          title: "Enterprise Solutions", 
          description: "Scalable business solutions",
          image: "/path/to/enterprise.jpg"
        },
        { 
          title: " 'The Pizza Party' ", 
          description: "Strengthen your team, boost morale. With engaging team building exercises.",
          image: "/path/to/enterprise.jpg"
        }
      ],
      community: [
        { 
          title: "Community Festivals", 
          description: "Bring the community together.",
          image: "/path/to/local.jpg"
        },
        { 
          title: "Charity Events", 
          description: "Supporting local causes, developing the vibe of the community."
        },
        { 
          title: "Cultural Celebrations", 
          description: "Honoring traditions, embracing diversity, tightening your community.",
          image: "/path/to/environmental.jpg"
        },
        { 
          title: "Holiday Parties", 
          description: "Bringing people together",
          image: "/path/to/cultural.jpg"
        }
      ]
    };

  const handleNext = (category) => {
    setIndices(prev => ({
      ...prev,
      [category]: Math.min(prev[category] + 1, services[category].length - 3)
    }));
  };

  const handlePrev = (category) => {
    setIndices(prev => ({
      ...prev,
      [category]: Math.max(prev[category] -1, 0)
    }));
  };

  const renderServiceCard = (service, index) => (
    <div key={index} className="service-card" onClick={() => handleServiceCardClick(service)}>
      {service.image && (
        <div className="service-image-container">
          <img 
            src={service.image} 
            alt={service.title}
            className="service-image"
            onError={(e) => {
                e.target.style.display = 'none';
            }}
          />
        </div>
      )}
      <div className="service-content">
        <h3>{service.title}</h3>
        <p>{service.description}</p>
      </div>
    </div>
  );

  const renderServiceCategory = (category, title) => (
    <div className="category-section">
      <h2 className="category-title">{title}</h2>
      <div className="category-content">
        <button
          className="nav-button prev"
          onClick={() => handlePrev(category)}
          disabled={indices[category] === 0}
        >
          <ChevronLeft size={24} />
        </button>
  
        <div className="services-row">
          {services[category]
            .slice(indices[category], indices[category] + 3)
            .map((service, index) => renderServiceCard(service, index))}
        </div>
  
        <button
          className="nav-button next"
          onClick={() => handleNext(category)}
          disabled={indices[category] >= services[category].length - 3}
        >
          <ChevronRight size={24} />
        </button>
      </div>
        
      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
  {selectedService && (
    <div className="modal-wrapper">
      <h2 className="modal-title">{selectedService.title}</h2>
      
      {selectedService.image && (
        <div className="modal-image-container">
          <img
            src={selectedService.image}
            alt={selectedService.title}
            className="modal-image"
          />
        </div>
      )}

      <div className="modal-details">
        {selectedService.modalContent && (
          <>
            <p className="modal-description">{selectedService.modalContent.details}</p>
            
            {selectedService.modalContent.features && (
              <div className="modal-features">
                <h3 className="features-title">What We Offer</h3>
                <ul className="features-list">
                  {selectedService.modalContent.features.map((feature, index) => (
                    <li key={index} className="feature-item">{feature}</li>
                  ))}
                </ul>
              </div>
            )}

            {selectedService.modalContent.pricing && (
              <div className="modal-pricing">
                <h3 className="pricing-title">Pricing</h3>
                <p className="pricing-text">{selectedService.modalContent.pricing}</p>
              </div>
            )}

            {selectedService.modalContent.additionalInfo && (
              <div className="modal-additional-info">
                {selectedService.modalContent.additionalInfo}
              </div>
            )}
          </>
        )}
      </div>

      <div className="modal-actions">
      <button 
        className="modal-button book-button"
        onClick={(e) => handleEmailClick(e, selectedService)}
        >
            Book Now
        </button>
      </div>
    </div>
  )}
</Modal>
                    

    </div>
  );

  return (
    <div className="services-page">
      <h1>Our Services</h1>
      
      <div className="desktop-view">
        {renderServiceCategory('family', 'Family Services')}
        {renderServiceCategory('b2b', 'B2B Solutions')}
        {renderServiceCategory('community', 'Community Programs')}
      </div>

      <div className="mobile-view">
        <div className="mobile-grid">
          {Object.entries(services).map(([category, items]) => (
            <div key={category} className="mobile-category">
              <h2 className="category-title">
                {category === 'family' ? 'Family Services' :
                 category === 'b2b' ? 'B2B Solutions' :
                 'Community Programs'}
              </h2>
              <div className="mobile-services-grid">
                {items.map((service, index) => renderServiceCard(service, index))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};



export default Services;