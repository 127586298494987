import React from 'react';
import { Users, Sun, MessageCircle, Handshake } from 'lucide-react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar.js';
import './CSS/AboutUs.css';
import Services from './Services';
import XayImg from "./images/Images About/image1.jpg"
import AzzanImg from "./images/Images About/image2.jpg"
import JoeImg from "./images/Images About/image3.jpg"
import ZayImg from "./images/Images About/image5.jpg"


const AboutPage = () => {
  /* React: Component-*/
  const TeamMember = ({ name, role, ProfileImage }) => (
    <div className="team-member">
      <img
        src={ProfileImage}
        alt={name}
        className="team-member-image"
      />
      <h3 className="team-member-name">{name}</h3>
      <p className="team-member-role">{role}</p>
    </div>
  );

  const ValueCard = ({ icon: Icon, title, description }) => (
    <div className="value-card">
      <div className="value-card-header">
        <Icon size={24} className="value-card-icon" />
        <h3 className="value-card-title">{title}</h3>
      </div>
      <p className="value-card-description">{description}</p>
    </div>
  );

  return (

    <div className="about-page">
      
      {/* Hero Section */}
      <div className="hero-section">
        <h1 className="hero-title">
          Shaping the Future of
          <span className="hero-title-accent"> Entertainment</span>
        </h1>
        <p className="hero-description">
          We're a team of passionate innovators dedicated to creating transformative 
          solutions that empower teens and enhance the educational experience. We work out of the Augusta
          GA area, and are avilable for event or service management throughout Richmond and Columbia counties. 
        </p>
        <a href = "https://www.kickstarter.com/" target='blank'>
        <button className="primary-button">
          Join Our Journey
        </button>
        </a>
      </div>

      {/* Values Section */}
      <div className="values-section">
        <h2 className="section-title">
          Our Core Values
        </h2>
        <div className="values-grid">
          <ValueCard 
            icon={Users}
            title="People First"
            description="We prioritize the well-being and unique needs of our clients, creating inclusive and supportive spaces where everyone feels valued and heard."
          />
          <ValueCard 
            icon={Sun}
            title="Empowerment & Growth"
            description="Through engaging experiences, we empower teens to explore their potential, build confidence, and embrace personal growth."
          />
          <ValueCard 
            icon={Handshake}
            title="Impacting the Community"
            description="Our solutions are designed to make a positive impact on the community at large, bridging gaps and creating opportunities across social settings."
          />
          <ValueCard 
            icon={MessageCircle}
            title="Open Communication"
            description="We foster an environment of transparency and open dialogue, both within our team and with our clients and partners."
          />
        </div>
      </div>

      {/* Team Section */}
      <div className="team-section">
        <div className="team-content">
          <h2 className="section-title">
            Meet Our Team
          </h2>
          <p className="section-description">
            Our diverse team brings together expertise from various fields to deliver 
            innovative solutions to make your dreams reality.
          </p>
          <div className="team-grid">
            <TeamMember name="Xavier Fluellen" role="Chief Executive Officer" ProfileImage= {XayImg}/>
            <TeamMember name="Azzan Gaskins" role="Chief Technology Officer" ProfileImage={AzzanImg} />
            <TeamMember name="Joseph Sheppard" role="Chief Operations Officer" ProfileImage={JoeImg} />
            <TeamMember name="Name Absent" role="Chief Engagement Officer" ProfileImage={ZayImg} />
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <div className="contact-section">
        <div className="contact-container">
          <h2 className="section-title">
            Let's Work Together
          </h2>
          <p className="section-description">
            Ready to start your new journey? We're here to help 
            turn your visions into reality.
          </p>
          <div className="contact-buttons">
            <button className="primary-button">
              Contact Us
            </button>
            <button className="secondary-button">
              View Our Work
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function App() {
  return (
    <Router>
      <div className="app">
        <Navbar />
        <Routes>
          <Route path="/" element={<AboutPage />} />
          <Route path="/services" element={<Services />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;